<template>
  <section class="container">
    <div class="row mt-5">
      <div class="col-auto p-0">
        <h3>
          <b>{{ $t("global.title.supplierPriceGroup") }}</b>
        </h3>
      </div>
      <div class="col-auto ml-auto">
        <BaseActionButton type="button" v-on:on-click="setEditable">
          <font-awesome-icon :icon="['far', 'edit']" class="icon" />
        </BaseActionButton>
      </div>
    </div>

    <div class="col-auto p-0">
      <h5>
        <b>{{ $t("global.title.pension") }}</b>
      </h5>
    </div>
    <PriceGroupDropdown
      v-model="supplier.pensionPriceGroups"
      :disabled="disabled"
    />
    <div class="col-auto p-0">
      <h5>
        <b>{{ $t("global.title.insurance") }}</b>
      </h5>
    </div>
    <PriceGroupDropdown
      v-model="supplier.insurancePriceGroups"
      :disabled="disabled"
    />
    <div class="row">
      <div class="col-12">
        <hr />
      </div>
    </div>

    <div class="row">
      <b-form-group id="signup-emailForm" class="col-3">
        <BaseActionButton
          class="w-100 btn-update"
          type="button"
          v-on:on-click="onSubmit"
          v-bind:clicked="clicked"
          v-bind:disabled="disabled && !clicked"
        >
          {{ $t("global.button.update") }}
        </BaseActionButton>
      </b-form-group>
    </div>
  </section>
</template>
<script>
import PriceGroupDropdown from "@/modules/general/supplier/components/PriceGroupDropdown.vue";

export default {
  name: "SupplierPriceGroup",
  components: { PriceGroupDropdown },
  props: ["value"],
  data() {
    return {
      clicked: false,
      disabled: true,
      agreementIndex: 0,
    };
  },
  async mounted() {
    this.$parent.$emit("updateLoader");
  },
  methods: {
    setEditable() {
      this.disabled = !this.disabled;
    },
    async onSubmit() {
      this.clicked = true;
      var payload = this.supplier;
      var id = this.id;
      try {
        var result = await this.$store.dispatch("supplierVuex/update", {
          payload,
          id,
        });
        this.clicked = false;
        this.toast("Success", "Pensionselskabet er nu opdateret", true);
      } catch (error) {
        this.clicked = false;
        this.toast(
          error.response.data.code,
          error.response.data.message, false
        );
      }
    },
  },
  computed: {
    id() {
      return this.$route.params.supplierId;
    },
    supplier() {
      return this.$store.getters["supplierVuex/supplier"];
    },
  },
};
</script>

<style lang="scss">
</style>