<template>
	<section>
		<div class="row">
			<div class="col-lg-4 col-12 mb-1">
				<select class="form-control mx-auto" ref="selector">
					<option v-for="(obj, index) in inputValue" :key="index" :value="obj" :selected="index === 0">
						{{ obj }}
					</option>
				</select>
			</div>

			<div class="col-auto">
				<b-button @click="editGroup" :disabled="disabled" v-if="value.length > 0" class="btn-update"> {{ $t('global.button.edit') }}</b-button>
			</div>

			<div class="col-auto">
				<b-button @click="addGroup" :disabled="disabled" class="btn-add"> {{ $t('global.button.add') }}</b-button>
			</div>

			<div class="col-auto">
				<b-button @click="deleteGroup" :disabled="disabled" class="btn-delete"> {{ $t('global.button.delete') }}</b-button>
			</div>
		</div>
	</section>
</template>
<script>
export default {
	name: 'PriceGroupDropdown',
	props: {
		value: Array,
		disabled: Boolean,
	},
	mounted() {},
	computed: {
		inputValue: {
			get() {
				return this.value
			},
			set(val) {
				this.$emit('input', val)
			},
		},
	},
	methods: {
		async addGroup() {
			const { value: groupName } = await this.$swal.fire({
				title: 'Indsæt prisgruppe navn',
				input: 'text',
				inputValidator: (value) => {
					if (!value) {
						return 'You need to write something!'
					}
				},
			})
			if (groupName) {
				this.value.push(groupName)
			}
		},
		async editGroup() {
			var currentGroupName = this.$refs.selector.value
			const { value: newGroupName } = await this.$swal.fire({
				title: 'Ændr prisgruppe navn',
				input: 'text',
				inputValue: currentGroupName,
				inputValidator: (value) => {
					if (!value) {
						return 'You need to write something!'
					}
				},
			})
			if (currentGroupName && newGroupName) {
				var index = this.inputValue.indexOf(currentGroupName)
				if (index !== -1) {
					this.inputValue.splice(index, 1, newGroupName)
				}
			}
		},
		async deleteGroup() {
			var currentGroupName = this.$refs.selector.value
			var index = this.inputValue.indexOf(currentGroupName)
			this.inputValue.splice(index, 1)
		},
	},
}
</script>
<style lang="scss"></style>
